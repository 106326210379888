import { differenceOfTwoDate, sleep } from './util.timer'
import { httpCall } from './api'
import { useAuthStore } from '../stores/auth'
import { useProfileStore } from '../stores/profile'

/**
 * Decode Jwt Token with Window atob
 * @param jwtToken | @returns a object
 */
export const getDecodeToken = (jwtToken: string|null) => {
	if (!jwtToken || (typeof jwtToken === 'string' && jwtToken?.trim()?.length === 0) || typeof window === 'undefined') return null
	const jwtArrays = jwtToken.split('.')
	const jwtPayloads: any = jwtArrays.length > 1 ? jwtArrays[1] : null
	try {
		const parsedToken = JSON.parse(window.atob(jwtPayloads))
		return parsedToken
	} catch (_error) {
		return null
	}
}

/**
 * Calculate Jwt Token Expired time
 * @param jwtToken | @returns a object
 */
export const getExpiredToken = (parsedToken: { iat: number; exp: number }) => {
	if (!parsedToken) return null
	const iatTime = (parsedToken?.iat || 0) * 1000
	const expTime = (parsedToken?.exp || 0) * 1000

	const resultDuration = differenceOfTwoDate(iatTime, expTime, 'minute')
	const resultCurrent = differenceOfTwoDate(new Date().getTime(), expTime, 'minute')

	if (resultCurrent > 0 && resultCurrent <= resultDuration) {
		return { expired: false, ...parsedToken }
	} else {
		return { expired: true }
	}
}

// Doing Refresh
export const doRefresh = async () => {
	await sleep(50)
	const authStore = useAuthStore()
	const resultRequest = await httpCall({ method: 'post', url: '/v1/api/auth/refresh-token', data: { refresh_token: authStore.authUser?.refresh_token } })
	if (resultRequest.code === 201) {
		const dataCurrent = resultRequest.data || null
		const data = {
			refresh_token: authStore?.authUser?.refresh_token,
			access_token: dataCurrent.access_token
		}
		authStore.setAuthUser(data)
	}
}

// Doing logout
export const doLogout = async () => {
	await sleep(100)
	const authStore = useAuthStore()
	const profileStore = useProfileStore()
	await httpCall({ method: 'patch', url: '/v1/api/auth/revoke-token', data: { refresh_token: authStore.authUser?.refresh_token } })
	profileStore.clear()
	authStore.clear()
}

export const setUserProfile = async () => {
	await sleep(50)
	const profileStore = useProfileStore()
	const resultRequest = await httpCall({ method: 'get', url: '/v1/api/auth/me' })
	if (resultRequest.code === 200) {
		const dataCurrent = resultRequest.data.data || {}
		profileStore.setUser(dataCurrent)
		return true
	}
}
